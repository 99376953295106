import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import useLocalStorage from "hooks/useLocalStorage";
import React from "react";

export function Section({
  title,
  icon,
  children = "",
  defaultIsOpen = false,
}: {
  title: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
  defaultIsOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useLocalStorage(
    `${title}SectionIsOpen`,
    defaultIsOpen
  );

  return (
    <div>
      <h2>
        <button
          type="button"
          className="w-full flex justify-between items-center p-5 bg-gray-800 text-white"
          onClick={(event) => {
            event.preventDefault();
            setIsOpen(!isOpen);
          }}
        >
          <span className="flex items-center">
            {icon} {title}
          </span>
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5" />
          ) : (
            <ChevronDownIcon className="h-5 w-5" />
          )}
        </button>
      </h2>

      {isOpen && <div className="w-full bg-white px-5 py-10">{children}</div>}
    </div>
  );
}

export function Sections({ children }: { children: React.ReactNode }) {
  return (
    <div className="border border-solid border-zinc-950/10 divide-y divide-zinc-950/10">
      {children}
    </div>
  );
}
