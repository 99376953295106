import { xor, round } from "lodash";
import { uuidv4 } from "services/uuid";
import { ICharge, INouvelleVariante } from "types";

export function getEmptyVariante(): INouvelleVariante {
  const l1 = uuidv4();
  const l2 = uuidv4();
  const l3 = uuidv4();
  const c1 = uuidv4();
  const c2 = uuidv4();
  const c3 = uuidv4();
  return {
    nom: "",
    projet: "",
    est_verrouillee: false,
    est_masquee: false,
    prix_vente_initial: 0,
    prix_achat_FAI: 0,
    prix_vendeur_HT: 0,
    prix_vendeur_taux_TVA: 0,
    prix_vendeur_TVA: 0,
    prix_vendeur_TTC: 0,

    frais_agence_charge: "ACQUEREUR",
    frais_agence_pourcentage: 6,
    frais_agence_HT: 0,
    frais_agence_taux_TVA: 20,
    frais_agence_TVA: 0,
    frais_agence_TTC: 0,

    frais_notaire_pourcentage: 3,
    frais_notaire_HT: 0,
    frais_notaire_taux_TVA: 0,
    frais_notaire_TVA: 0,
    frais_notaire_TTC: 0,

    ponderation_lots: "PRIX_DE_REVENTE",
    duree_projet: 0,
    taux_commission_engagement: 1,
    duree_commission_engagement: 12,
    frais_de_dossier: 3000,
    taux_interet_emprunt: 3,
    duree_interet_emprunt: 12,
    taux_hypotheque: 1,
    pourcentage_apport: 20,
    apport: 0,
    taux_interet_apport: 0,
    duree_pret_apport: 0,
    cout_levee_de_fond: 0,
    charges: {
      c1: {
        id: c1,
        ordre: 1,
        designation: "Charge 1",
        HT: 0,
        taux_TVA: 20,
        TVA: 0,
        TTC: 0,
        validee: false,
        lots: {
          [l1]: true,
          [l2]: true,
          [l3]: true,
        },
      },
      c2: {
        id: c2,
        ordre: 2,
        designation: "Charge 2",
        HT: 0,
        taux_TVA: 20,
        TVA: 0,
        TTC: 0,
        validee: false,
        lots: {
          [l1]: true,
          [l2]: true,
          [l3]: true,
        },
      },
      c3: {
        id: c3,
        ordre: 3,
        designation: "Charge 3",
        HT: 0,
        taux_TVA: 20,
        TVA: 0,
        TTC: 0,
        validee: false,
        lots: {
          [l1]: true,
          [l2]: true,
          [l3]: true,
        },
      },
    },
    lots: {
      [l1]: {
        id: l1,
        ordre: 1,
        designation: "Lot 1",
        prix_revente: 0,
        surface: 0,
        pourcentage_TVA: 20,
        type_TVA: "TOTALE",
      },
      [l2]: {
        id: l2,
        ordre: 2,
        designation: "Lot 2",
        prix_revente: 0,
        surface: 0,
        pourcentage_TVA: 20,
        type_TVA: "TOTALE",
      },
      [l3]: {
        id: l3,
        ordre: 3,
        designation: "Lot 3",
        prix_revente: 0,
        surface: 0,
        pourcentage_TVA: 20,
        type_TVA: "TOTALE",
      },
    },
    financements: [],
    notes: "",
  };
}

function calculer_cout_revient_operationnel<V extends INouvelleVariante>(
  variante: V
): number {
  let cout_revient_operationnel =
    variante.prix_vendeur_TTC +
    variante.frais_notaire_TTC +
    variante.frais_agence_TTC;
  for (const idCharge in variante.charges) {
    const charge = variante.charges[idCharge];
    cout_revient_operationnel += charge.TTC;
  }
  return cout_revient_operationnel;
}

function arrondirVariante<V extends INouvelleVariante>(variante: V): V {
  return {
    ...variante,
    prix_vente_initial: round(variante.prix_vente_initial, 2),
    prix_achat_FAI: round(variante.prix_achat_FAI, 2),
    prix_vendeur_HT: round(variante.prix_vendeur_HT, 2),
    prix_vendeur_taux_TVA: round(variante.prix_vendeur_taux_TVA, 2),
    prix_vendeur_TVA: round(variante.prix_vendeur_TVA, 2),
    prix_vendeur_TTC: round(variante.prix_vendeur_TTC, 2),
    frais_agence_charge: variante.frais_agence_charge,
    frais_agence_pourcentage: round(variante.frais_agence_pourcentage, 2),
    frais_agence_HT: round(variante.frais_agence_HT, 2),
    frais_agence_taux_TVA: round(variante.frais_agence_taux_TVA, 2),
    frais_agence_TVA: round(variante.frais_agence_TVA, 2),
    frais_agence_TTC: round(variante.frais_agence_TTC, 2),
    frais_notaire_pourcentage: round(variante.frais_notaire_pourcentage, 2),
    frais_notaire_HT: round(variante.frais_notaire_HT, 2),
    frais_notaire_taux_TVA: round(variante.frais_notaire_taux_TVA, 2),
    frais_notaire_TVA: round(variante.frais_notaire_TVA, 2),
    frais_notaire_TTC: round(variante.frais_notaire_TTC, 2),
    charges: Object.values(variante.charges).reduce(
      (acc, charge) => {
        acc[charge.id] = {
          ...charge,
          HT: round(charge.HT),
          taux_TVA: round(charge.taux_TVA),
          TVA: round(charge.TVA),
          TTC: round(charge.TTC),
        };
        return acc;
      },
      {} as { [x: string]: ICharge }
    ),
  };
}

function HTFromTTCAndTaux_TVA(TTC: number, taux_TVA: number): number {
  return (100 * TTC) / (100 + taux_TVA);
}

function TVAFromHTAndTaux_TVA(HT: number, taux_TVA: number): number {
  return (HT * taux_TVA) / 100;
}

function TVAFromTTCAndTauxTVA(TTC: number, taux_TVA: number): number {
  return (TTC * taux_TVA) / (100 + taux_TVA);
}

export function recalculer_variante<V extends INouvelleVariante>(
  ancienne_variante: V,
  nouvelle_variante: V
): V {
  const variante: V = {
    ...nouvelle_variante,
  };

  if (
    ancienne_variante.frais_agence_TTC !== variante.frais_agence_TTC &&
    variante.prix_vendeur_TTC !== 0
  ) {
    variante.frais_agence_pourcentage =
      (100 * variante.frais_agence_TTC) / variante.prix_vendeur_TTC;
  }

  if (ancienne_variante.frais_notaire_TTC !== variante.frais_notaire_TTC) {
    variante.frais_notaire_pourcentage =
      variante.frais_agence_charge === "ACQUEREUR"
        ? variante.prix_vendeur_TTC === 0
          ? 0
          : (100 * variante.frais_notaire_TTC) / variante.prix_vendeur_TTC
        : variante.prix_achat_FAI === 0
          ? 0
          : (100 * variante.frais_notaire_TTC) / variante.prix_achat_FAI;
  }

  if (ancienne_variante.prix_achat_FAI === variante.prix_achat_FAI) {
    variante.frais_agence_TTC =
      (variante.prix_vendeur_TTC * variante.frais_agence_pourcentage) / 100;
    variante.prix_achat_FAI =
      variante.prix_vendeur_TTC + variante.frais_agence_TTC;
  } else {
    const pourcentage_FAI = 1 + variante.frais_agence_pourcentage / 100;
    variante.prix_vendeur_TTC =
      pourcentage_FAI === 0 ? 0 : variante.prix_achat_FAI / pourcentage_FAI;
    variante.frais_agence_TTC =
      variante.prix_achat_FAI - variante.prix_vendeur_TTC;
  }

  variante.frais_notaire_TTC =
    variante.frais_agence_charge === "ACQUEREUR"
      ? (variante.prix_vendeur_TTC * variante.frais_notaire_pourcentage) / 100
      : (variante.prix_achat_FAI * variante.frais_notaire_pourcentage) / 100;

  variante.prix_vendeur_HT = HTFromTTCAndTaux_TVA(
    variante.prix_vendeur_TTC,
    variante.prix_vendeur_taux_TVA
  );
  variante.prix_vendeur_TVA = TVAFromTTCAndTauxTVA(
    variante.prix_vendeur_TTC,
    variante.prix_vendeur_taux_TVA
  );
  variante.frais_agence_HT = HTFromTTCAndTaux_TVA(
    variante.frais_agence_TTC,
    variante.frais_agence_taux_TVA
  );
  variante.frais_agence_TVA = TVAFromHTAndTaux_TVA(
    variante.frais_agence_HT,
    variante.frais_agence_taux_TVA
  );
  variante.frais_notaire_HT = HTFromTTCAndTaux_TVA(
    variante.frais_notaire_TTC,
    variante.frais_notaire_taux_TVA
  );
  variante.frais_notaire_TVA = TVAFromHTAndTaux_TVA(
    variante.frais_notaire_HT,
    variante.frais_notaire_taux_TVA
  );

  const idsLotsSupprimes = xor(
    Object.keys(ancienne_variante.lots),
    Object.keys(variante.lots)
  );

  for (const id in variante.charges) {
    const charge = variante.charges[id];
    charge.HT = HTFromTTCAndTaux_TVA(charge.TTC, charge.taux_TVA);
    charge.TVA = TVAFromHTAndTaux_TVA(charge.HT, charge.taux_TVA);

    for (let j = 0; j < idsLotsSupprimes.length; j++) {
      const idLotSupprime = idsLotsSupprimes[j];
      delete charge.lots[idLotSupprime];
    }
  }

  for (const idLot in variante.lots) {
    for (const idCharge in variante.charges) {
      const charge = variante.charges[idCharge];
      if (!charge.lots.hasOwnProperty(idLot)) {
        charge.lots[idLot] = true;
      }
    }
  }

  const ancien_cout_revient_operationnel =
    calculer_cout_revient_operationnel(ancienne_variante);
  const nouveau_cout_revient_operationnel =
    calculer_cout_revient_operationnel(variante);
  if (ancienne_variante.pourcentage_apport !== variante.pourcentage_apport) {
    variante.apport = round(
      (nouveau_cout_revient_operationnel * variante.pourcentage_apport) / 100,
      2
    );
  }
  if (
    ancienne_variante.apport !== variante.apport ||
    ancien_cout_revient_operationnel !== nouveau_cout_revient_operationnel
  ) {
    variante.pourcentage_apport = round(
      (variante.apport * 100) / nouveau_cout_revient_operationnel,
      2
    );
  }

  return arrondirVariante(variante);
}
