import queryString from "query-string";

export function parseVarianteIdFromSearch(search: string): string | null {
  let { variante_id } = queryString.parse(search);
  if (typeof variante_id !== "string") {
    variante_id = null;
  }
  return variante_id;
}

export function createSearchWithVarianteId(variante_id: string | null) {
  if (variante_id === null) return "";
  return `?variante_id=${variante_id}`;
}
