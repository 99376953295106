import { IFinancement } from "types";
import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "catalyst/dialog";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { Input } from "catalyst/input";
import { Field, Label } from "catalyst/fieldset";
import { Select } from "catalyst/select";
import InputNumber from "components/form/InputNumber";
import { uuidv4 } from "services/uuid";
import { TrashIcon } from "@heroicons/react/24/outline";
import AjouterLigneDiv from "./AjouterLigneDiv";
import { Code } from "catalyst/text";

interface NouveauFinancementModalProps {
  isOpen: boolean;
  hide: () => void;
  onSubmit: (financement: IFinancement) => void;
}

function dansUnAn() {
  return new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    .toISOString()
    .substring(0, 10);
}

function maintenant() {
  return new Date().toISOString().substring(0, 10);
}

export default function NouveauFinancementModal({
  isOpen,
  hide,
  onSubmit,
}: NouveauFinancementModalProps) {
  const { register, control, reset, handleSubmit } = useForm<IFinancement>({
    defaultValues: {
      lignes: [
        {
          id: uuidv4(),
          type: "DEBLOCAGE",
          montant: 500_000,
          tauxInteret: 4,
          description: "Déblocage",
          date: maintenant(),
        },
        {
          id: uuidv4(),
          type: "REMBOURSEMENT",
          montant: -500_000,
          description: "Remboursement",
          date: dansUnAn(),
        },
      ],
      nom: "Financement",
      id: uuidv4(),
    },
  });
  const {
    fields: lignes,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: "lignes",
  });
  const watchedLignes = useWatch({
    control: control,
    name: "lignes",
  });
  return (
    <Dialog open={isOpen} onClose={hide} size="5xl">
      <DialogTitle>Ajouter un financement</DialogTitle>
      <DialogDescription>
        Comment allez-vous financer ce projet? Si vous financez ce bien avec un
        prêt bancaire, ajouter le montant total débloqué en ajoutant une ligne{" "}
        <Code className="capitalize">Déblocage</Code>. Puis rajouter un{" "}
        <Code className="capitalize">Remboursement</Code> à la date à laquelle
        vous allez rembourser ce prêt. Vous pouvez rajouter des{" "}
        <Code className="capitalize">Frais Fixes</Code> ou un{" "}
        <Code className="capitalize">changement de taux</Code> si besoin.
      </DialogDescription>
      <DialogBody>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4 mb-5">
          <Field>
            <Label>Nom</Label>
            <Input {...register(`nom`)} autoFocus />
          </Field>
        </div>
        <div className="space-y-4">
          {lignes.map((ligne, i) => (
            <div
              key={ligne.id}
              className="grid grid-cols-1 gap-8 sm:grid-cols-6 sm:gap-4"
            >
              <Field>
                <Label>Description</Label>
                <Input {...register(`lignes.${i}.description`)} autoFocus />
              </Field>
              <Field>
                <Label>Date</Label>
                <Input {...register(`lignes.${i}.date`)} type="date" />
              </Field>
              <Field>
                <Label>Type</Label>
                <Select {...register(`lignes.${i}.type`)}>
                  <option value="DEBLOCAGE">Déblocage</option>
                  <option value="CHANGEMENT_DE_TAUX">Changement de taux</option>
                  <option value="COUT_FIXE">Frais fixe</option>
                  <option value="REMBOURSEMENT">Remboursement</option>
                </Select>
              </Field>
              {watchedLignes?.[i]?.type !== "CHANGEMENT_DE_TAUX" && (
                <Field>
                  <Label>Montant</Label>
                  <InputNumber name={`lignes.${i}.montant`} />
                </Field>
              )}
              {(watchedLignes?.[i]?.type === "DEBLOCAGE" ||
                watchedLignes?.[i]?.type === "CHANGEMENT_DE_TAUX") && (
                <Field>
                  <Label>Taux</Label>
                  <InputNumber
                    name={`lignes.${i}.tauxInteret`}
                    type="percentage"
                  />
                </Field>
              )}
              <div className="self-end w-16">
                <button
                  onClick={() => remove(i)}
                  className="text-red-600 hover:text-red-900 mb-2"
                >
                  <TrashIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <AjouterLigneDiv className="mt-10" append={append} />
      </DialogBody>
      <DialogActions>
        <Button
          key="annuler"
          color="white"
          onClick={() => {
            reset();
            hide();
          }}
        >
          Annuler
        </Button>
        <Button type="submit" onClick={handleSubmit(onSubmit)} color="blue">
          Ajouter un financement
        </Button>
      </DialogActions>
    </Dialog>
  );
}
