import React, { useCallback, useEffect, useState } from "react";
import { IPermission, IProjet } from "types";
import useModal from "hooks/useModal";
import {
  getPartagesForProjet as getPermissionsForProjet,
  partagerUnProjet,
  supprimerPermission,
} from "services/api";
import { useAuth } from "auth/authContext";
import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "catalyst/dialog";
import { useForm } from "react-hook-form";
import { Field, Label } from "catalyst/fieldset";
import { Input } from "catalyst/input";
import { Select } from "catalyst/select";
import { TrashIcon } from "@heroicons/react/24/outline";

interface PartagerProjetModalProps {
  projet: IProjet;
  children: (show: () => void) => React.ReactNode;
}

export default function PartagerProjetModal({
  projet,
  children,
}: PartagerProjetModalProps) {
  const { currentUser } = useAuth();
  const { isOpen, show, hide } = useModal(false);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<IPermission[]>([]);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      role: "EDITEUR",
    },
  });

  const getPermissionsCallback = useCallback(() => {
    setError(null);
    getPermissionsForProjet(projet).then((response) => {
      setPermissions(response.data.results);
    });
  }, [projet]);

  useEffect(() => {
    getPermissionsCallback();
  }, [getPermissionsCallback]);
  const rolesLabels = {
    EDITEUR: "Editeur",
    LECTEUR: "Lecteur",
    ADMIN: "Propriétaire",
  };
  return (
    <>
      <Dialog open={isOpen} onClose={hide}>
        <DialogTitle>
          Partager ce projet avec un utilisateur de Bopar
        </DialogTitle>
        <DialogBody>
          <p className="text-lg">Ajouter une permission</p>
          <div className="flex gap-4">
            <form
              id="share-project-form"
              onSubmit={handleSubmit(({ email, role }) => {
                partagerUnProjet(projet, email, role)
                  .then(getPermissionsCallback)
                  .catch(() => {
                    setError(
                      `Impossible de partager ce projet avec ${email}. Assurez vous que l'email est correct.`
                    );
                  });
              })}
            >
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
                <Field>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    autoFocus
                    {...register("email", { required: true })}
                  />
                </Field>
                <Field>
                  <Label>Role</Label>
                  <Select {...register("role")}>
                    <option value="EDITEUR">Editeur</option>
                    <option value="LECTEUR">Lecteur</option>
                    <option value="ADMIN">Propriétaire</option>
                  </Select>
                </Field>
              </div>
            </form>

            <div className="mt-5">
              {error && <p className="font-bold mb-3 text-red-500">{error}</p>}
            </div>
          </div>
          {permissions.length === 0 ? (
            <div className="mt-5">
              <p className="font-bold mb-3">
                Il n'y a pas de permissions associées à ce projet
              </p>
            </div>
          ) : (
            <div className="mt-8">
              <p className="text-lg ">Permissions</p>

              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <tbody className="divide-y divide-gray-200">
                      {permissions.map((permission) => (
                        <tr key={permission.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-zinc-950 sm:pl-6 md:pl-0">
                            {permission.user.email}
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            {rolesLabels[permission.role]}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 md:pr-0">
                            {currentUser?.email !== permission.user.email && (
                              <Button
                                type="button"
                                onClick={() => {
                                  supprimerPermission(permission).then(
                                    getPermissionsCallback
                                  );
                                }}
                                className="ml-5"
                                color="red"
                              >
                                <TrashIcon className="stroke-white" />
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </DialogBody>
        <DialogActions>
          <Button key="annuler" color="white" onClick={hide}>
            Fermer
          </Button>
          <Button type="submit" color="blue" form="share-project-form">
            Partager
          </Button>
        </DialogActions>
      </Dialog>
      {children(show)}
    </>
  );
}
