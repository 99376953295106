import { useEffect, useState } from "react";
import Page from "layout/Page";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getProjet,
  createVariante,
  updateVariante,
  deleteVariante,
  updateProjet,
  getDefaultVariante,
  changerOrdreVariantes,
} from "services/api";
import { INouvelleVariante, IProjet, IVariante } from "types";
import LoadingState from "components/LoadingState";
import useModal from "hooks/useModal";
import NouvelleVarianteModal from "./NouvelleVarianteModal";
import { getEmptyVariante } from "core/variante";
import Breadcrumb from "../components/Breadcrumb";
import { showError } from "alerts/alertsSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import PartagerProjetModal from "./PartagerProjetModal";
import {
  parseVarianteIdFromSearch,
  createSearchWithVarianteId,
} from "./filters";
import { OngletVarianteDraggable } from "./OngletVarianteDraggable";
import {
  CogIcon,
  PencilIcon,
  PlusIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { Button } from "catalyst/button";
import EditProjetModal from "./EditProjetModal";
import VarianteForm from "./Variante";
import { Tooltip } from "react-tooltip";

export default function PageProjet() {
  const location = useLocation();
  const search = location.search;
  const navigate = useNavigate();

  const [variantes, setVariantes] = useState<IVariante[]>([]);
  const [varianteId, setVarianteId] = useState<string | null>(
    parseVarianteIdFromSearch(search)
  );
  const dispatch = useDispatch<AppDispatch>();
  const [projet, setProjet] = useState<IProjet | null>(null);
  const { projet_id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const {
    isOpen: varianteModalIsOpen,
    show: showVarianteModal,
    hide: hideVarianteModal,
  } = useModal(false);
  const {
    isOpen: projetModalIsOpen,
    show: showProjetModal,
    hide: hideProjetModal,
  } = useModal(false);
  const [varianteParDefaut, setVarianteParDefault] =
    useState<INouvelleVariante>(getEmptyVariante);

  useEffect(() => {
    if (projet_id) {
      setIsLoading(true);
      getProjet(projet_id)
        .then((response) => {
          const p = response.data;
          setVariantes(p.variantes);
          setProjet(p);
          if (varianteId === null && p.variantes.length > 0) {
            setVarianteId(p.variantes[0].id);
          }
        })
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [projet_id, varianteId, setIsLoading]);

  useEffect(() => {
    getDefaultVariante().then(setVarianteParDefault).catch(console.error);
  }, []);

  useEffect(() => {
    if (varianteId) {
      navigate(
        `/projets/${projet_id}${createSearchWithVarianteId(varianteId)}`
      );
    } else {
      navigate(`/projets/${projet_id}`);
    }
  }, [projet_id, varianteId, navigate]);

  useEffect(() => {
    if (projet && variantes.length > 0 && !projet.partage) {
      changerOrdreVariantes(
        projet.id,
        variantes.map((v) => v.id)
      );
    }
  }, [projet, variantes]);

  const updateVariantes = (variante: IVariante) => {
    const varianteIndex = variantes.findIndex((v) => v.id === variante.id);
    setVariantes((prevVariantes) => {
      const nouvellesVariantes = prevVariantes.slice();
      nouvellesVariantes[varianteIndex] = variante;
      return nouvellesVariantes;
    });
    setVarianteId(variante.id);
  };

  if (isLoading) {
    return (
      <Page
        breadcrumb={
          <Breadcrumb
            pages={[
              { name: "Projects", to: "/projets" },
              { name: "Project", to: `/projets/${projet_id}` },
            ]}
          />
        }
      >
        <LoadingState />
      </Page>
    );
  }

  if (projet === null)
    return (
      <Page>
        <p>
          Ce projet n'existe pas, ou vous n'avez pas la possibilité de le voir
        </p>
        <p>
          Retourner à la <Link to="/projets">liste des projets</Link>
        </p>
      </Page>
    );

  return (
    <Page
      breadcrumb={
        <div className="flex gap-4">
          <Breadcrumb
            className="hidden sm:flex"
            pages={[
              { name: "Projects", to: "/projets" },
              { name: projet.nom, to: `/projets/${projet_id}` },
            ]}
          />
          <Button
            plain
            onClick={showProjetModal}
            className="pointer"
            data-tooltip-id="projet-tooltip"
            data-tooltip-content="Editer ce projet"
            data-tooltip-place="right"
          >
            <PencilIcon className="stroke-gray-900" />
          </Button>
        </div>
      }
      className="py-5"
    >
      <NouvelleVarianteModal
        isOpen={varianteModalIsOpen}
        hide={hideVarianteModal}
        onSubmit={({ nom }) => {
          createVariante({
            ...varianteParDefaut,
            nom,
            projet: projet.id,
          }).then((response) => {
            setVariantes((prevVariantes) => [...prevVariantes, response.data]);
            setVarianteId(response.data.id);
            hideVarianteModal();
          });
        }}
      />
      <EditProjetModal
        projet={projet}
        isOpen={projetModalIsOpen}
        hide={hideProjetModal}
        onSubmit={(newProjet) => {
          hideProjetModal();
          updateProjet(newProjet)
            .then(() => {
              setProjet(newProjet);
            })
            .catch(() => {
              dispatch(showError("Impossible de mettre à jour ce projet"));
            });
        }}
      />
      <Tooltip id="projet-tooltip" style={{ zIndex: 999 }} />
      {variantes.length === 0 ? (
        <div className="text-center mt-20">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-semibold text-zinc-950">
            Pas de variante
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Ce projet n'a pas de variante.
            <br />
            Créer une variante pour commencer.
          </p>
          <div className="mt-6">
            <Button onClick={showVarianteModal} color="blue">
              <PlusIcon className="stroke-white" />
              Ajouter une variante
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className=" overflow-x-auto">
            <nav className="-mb-px flex space-x-5">
              <Button
                plain
                onClick={showVarianteModal}
                className="pointer"
                data-tooltip-id="projet-tooltip"
                data-tooltip-content="Créer une variante"
                data-tooltip-place="right"
              >
                <PlusIcon className="stroke-gray-900" />
              </Button>
              {projet.permission === "ADMIN" && (
                <div>
                  <PartagerProjetModal projet={projet}>
                    {(s) => (
                      <Button
                        plain
                        onClick={s}
                        className="pointer"
                        data-tooltip-id="projet-tooltip"
                        data-tooltip-content="Partager ce projet"
                        data-tooltip-place="right"
                      >
                        <UserGroupIcon className="stroke-gray-900" />
                      </Button>
                    )}
                  </PartagerProjetModal>
                </div>
              )}
              {variantes.map((variante, i) => (
                <OngletVarianteDraggable
                  key={variante.id}
                  permission={projet.permission}
                  variante={variante}
                  index={i}
                  estActif={variante.id === varianteId}
                  deplacerTab={(dragIndex: number, hoverIndex: number) => {
                    setVariantes((prevVariantes) => {
                      const newVariantes = prevVariantes.slice();
                      const varianteDragged = newVariantes.splice(
                        dragIndex,
                        1
                      )[0];
                      newVariantes.splice(hoverIndex, 0, varianteDragged);
                      return newVariantes;
                    });
                  }}
                  tabMoved={(index) => {
                    setVarianteId(variantes[index].id);
                  }}
                  onClick={() => setVarianteId(variante.id)}
                  onChange={(nouvelleVariante) => {
                    updateVariante(nouvelleVariante).then((response) => {
                      updateVariantes(response.data);
                    });
                  }}
                  onDuplicate={(varianteDupliquee) => {
                    createVariante({
                      ...varianteDupliquee,
                      nom: `copie de ${varianteDupliquee.nom}`,
                    }).then((response) => {
                      const nouvelleVariante = response.data;
                      setVariantes((prevVariantes) => {
                        const newVariantes = prevVariantes.slice();
                        newVariantes.splice(i + 1, 0, nouvelleVariante);
                        return newVariantes;
                      });
                      setVarianteId(nouvelleVariante.id);
                    });
                  }}
                  onToggleLock={(v) => {
                    updateVariante({
                      ...v,
                      est_verrouillee: !v.est_verrouillee,
                    }).then((response) => {
                      updateVariantes(response.data);
                    });
                  }}
                  onToggleHide={(v) => {
                    updateVariante({
                      ...v,
                      est_masquee: !v.est_masquee,
                    }).then((response) => {
                      updateVariantes(response.data);
                    });
                  }}
                  onDelete={(varianteASupprimer) => {
                    deleteVariante(varianteASupprimer);
                    const newVariantes = variantes.filter(
                      (v) => v.id !== varianteASupprimer.id
                    );
                    setVariantes(newVariantes);
                  }}
                />
              ))}
            </nav>
          </div>
          <div>
            {variantes
              .filter((variante) => variante.id === varianteId)
              .map((variante, i) => (
                <div key={i}>
                  <VarianteForm
                    id="variante-form"
                    variante={variante}
                    onSubmit={(nouvelleVariante) => {
                      updateVariante(nouvelleVariante).then((response) => {
                        updateVariantes(response.data);
                      });
                    }}
                  />
                  <div className="text-right py-4">
                    <Button type="submit" color="blue" form="variante-form">
                      <CogIcon className="stroke-white" />
                      Lancer le calcul
                    </Button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Page>
  );
}
