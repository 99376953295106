import {
  IProjet,
  INouveauProjet,
  INouvelleVariante,
  IVariante,
  ICredentials,
  ICurrentUser,
  ITokenFromAPI,
  ISignUpForm,
  INewPassword,
  IPermission,
  ITokens,
} from "types";
import http from "./http";
import { paginationVersParametresAPI } from "./pagination";

export function refreshTokens(tokens: ITokens): Promise<ITokenFromAPI> {
  return http
    .post("/auth/jwt/refresh/", { refresh: tokens.refreshToken })
    .then((response) => response.data);
}

export function signUp(form: ISignUpForm): Promise<ICurrentUser> {
  return http.post("/auth/signup/", form).then((response) => response.data);
}

export function login(credentials: ICredentials): Promise<ITokenFromAPI> {
  return http
    .post("/auth/jwt/create/", credentials)
    .then((response) => response.data);
}

export function getCurrentUser(): Promise<ICurrentUser> {
  return http.get("/auth/users/me/").then((response) => response.data);
}

export function resetPassword(email: string) {
  return http.post("/auth/users/reset_password/", { email });
}

export function setPassword({
  current_password,
  new_password,
  re_new_password,
}: INewPassword) {
  return http.post("/auth/users/set_password/", {
    current_password,
    new_password,
    re_new_password,
  });
}

export function confirmResetPassword(
  uid: string,
  token: string,
  password: string
) {
  return http.post("/auth/users/reset_password_confirm/", {
    uid,
    token,
    new_password: password,
    re_new_password: password,
  });
}

export function activateAccount(uid: string, token: string) {
  return http.post("/auth/users/activation/", {
    uid,
    token,
  });
}

// projets
interface IGetProjects {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IProjet[];
  };
}

export function getProjets(
  page: number = 1,
  perPage: number = 100,
  search: string = "",
  tags: string[]
): Promise<IGetProjects> {
  return http.get("/api/bopar/projets/", {
    params: paginationVersParametresAPI(page, perPage, search, tags),
  });
}

interface IGetProjetTags {
  data: {
    count: number;
    results: string[];
  };
}
export function getProjetTags(): Promise<IGetProjetTags> {
  return http.get(`/api/bopar/projet_tags/`);
}

interface IGetProjet {
  data: IProjet;
}

export function getProjet(id: string): Promise<IGetProjet> {
  return http.get(`/api/bopar/projets/${id}/`);
}
interface ICreateProject {
  data: IProjet;
}

export function createProjet(projet: INouveauProjet): Promise<ICreateProject> {
  return http.post("/api/bopar/projets/", projet);
}

interface IUpdateProject {
  data: IProjet;
}

export function updateProjet(projet: IProjet): Promise<IUpdateProject> {
  return http.put(`/api/bopar/projets/${projet.id}/`, projet);
}

export function deleteProjet(projet: IProjet): Promise<void> {
  return http.delete(`/api/bopar/projets/${projet.id}/`);
}

// Variantes

interface IGetVariantes {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IVariante[];
  };
}

export function getVariantes(): Promise<IGetVariantes> {
  return http.get("/api/bopar/variantes/");
}

interface IGetVariante {
  data: IVariante;
}

export function getVariante(id: string): Promise<IGetVariante> {
  return http.get(`/api/bopar/variantes/${id}/`);
}
interface IVarianteCreation {
  data: IVariante;
}

export function createVariante(
  variante: INouvelleVariante
): Promise<IVarianteCreation> {
  return http.post("/api/bopar/variantes/", variante);
}

interface IUpdateVariante {
  data: IVariante;
}

export function updateVariante(variante: IVariante): Promise<IUpdateVariante> {
  return http.put(`/api/bopar/variantes/${variante.id}/`, variante);
}

export function deleteVariante(variante: IVariante): Promise<void> {
  return http.delete(`/api/bopar/variantes/${variante.id}/`);
}

// Ordre des variantes
export function changerOrdreVariantes(
  projet_id: string,
  variante_ids: string[]
): Promise<void> {
  return http.post("/api/bopar/ordre_variantes/", { projet_id, variante_ids });
}

// partages

interface IGetPartagesForProjet {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IPermission[];
  };
}

export function getPartagesForProjet(
  projet: IProjet
): Promise<IGetPartagesForProjet> {
  return http.get(`/api/bopar/permissions/projets/${projet.id}/`);
}

export function partagerUnProjet(
  projet: IProjet,
  email: string,
  role: string
): Promise<void> {
  return http.post(`/api/bopar/permissions/`, {
    email,
    projet_id: projet.id,
    role,
  });
}

export function supprimerPermission(permission: IPermission): Promise<void> {
  return http.delete(`/api/bopar/permissions/${permission.id}/`);
}

interface IGetProjetsPartages {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: IProjet[];
  };
}

export function getProjetsPartages(
  page: number = 1,
  perPage: number = 100,
  search: string = ""
): Promise<IGetProjetsPartages> {
  return http.get("/api/bopar/partages/projets/", {
    params: paginationVersParametresAPI(page, perPage, search),
  });
}

// Variante par défault
export function changeVarianteParDefault(
  variante_par_defaut: INouvelleVariante
): Promise<void> {
  return http.post(`/api/bopar/variantes_par_defaut/`, variante_par_defaut);
}

interface IGetVariantesParDefaut {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: INouvelleVariante[];
  };
}

export function getVariantesParDefaut(): Promise<IGetVariantesParDefaut> {
  return http.get("/api/bopar/variantes_par_defaut/");
}

export function getDefaultVariante(): Promise<INouvelleVariante> {
  return getVariantesParDefaut().then((response) => {
    const r = response.data.results;
    if (r.length > 0) {
      return Promise.resolve(r[0]);
    }
    return Promise.reject("pas de variante par defaut");
  });
}
