import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { AppDispatch, RootState } from "../store";
import { IAlert } from "types";

interface AlertsState {
  [x: string]: IAlert;
}

const initialState: AlertsState = {};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    show: (state, action: PayloadAction<IAlert>) => {
      state[action.payload.id] = action.payload;
    },
    hide: (state, action: PayloadAction<IAlert>) => {
      delete state[action.payload.id];
    },
  },
});

export const { show, hide } = alertsSlice.actions;

export const selectAlerts = (state: RootState) => state.alerts;

function showAndHideAfter10s(
  title = "",
  type: "success" | "danger" = "success"
) {
  return (dispatch: AppDispatch) => {
    const alert: IAlert = {
      id: `${Date.now().toString()}:${title}`,
      title,
      type,
    };
    dispatch(show(alert));
    setTimeout(() => dispatch(hide(alert)), 10000);
  };
}

export function showSuccess(title: string) {
  return showAndHideAfter10s(title, "success");
}

export function showError(title: string) {
  return showAndHideAfter10s(title, "danger");
}

export default alertsSlice.reducer;
