import React, { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import type { XYCoord, Identifier } from "dnd-core";
import { IVariante, IVariantePermission } from "types";
import {
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilSquareIcon,
  TrashIcon,
  CheckIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownSeparator,
} from "catalyst/dropdown";
import { Button } from "catalyst/button";
import { Input } from "catalyst/input";
import { useForm } from "react-hook-form";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

function EditVarianteNameForm({
  variante,
  onSubmit,
  onClose,
}: {
  variante: IVariante;
  onSubmit: (data: IVariante) => void;
  onClose: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<IVariante>({ defaultValues: variante });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center gap-2">
        <div style={{ minWidth: "max-content" }}>
          <Input type="text" autoFocus {...register("nom")} />
        </div>
        <div>
          <Button outline type="submit" disabled={!(isValid && isDirty)}>
            <CheckIcon />
          </Button>
        </div>
        <div>
          <Button outline type="button" onClick={() => onClose()}>
            <XMarkIcon />
          </Button>
        </div>
      </div>
    </form>
  );
}

export interface OngletVarianteDraggableProps {
  permission: IVariantePermission;
  variante: IVariante;
  index: number;
  estActif: boolean;
  deplacerTab: (dragIndex: number, hoverIndex: number) => void;
  tabMoved: (dragIndex: number) => void;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  onToggleLock: (variante: IVariante) => void;
  onToggleHide: (variante: IVariante) => void;
  onChange: (variante: IVariante) => void;
  onDuplicate: (variante: IVariante) => void;
  onDelete: (variante: IVariante) => void;
}

export function OngletVarianteDraggable({
  permission,
  variante,
  index,
  estActif,
  deplacerTab,
  tabMoved,
  onClick,
  onChange,
  onDuplicate,
  onDelete,
  onToggleLock,
  onToggleHide,
}: OngletVarianteDraggableProps) {
  const [editModeOn, setEditModeOne] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    DragItem,
    { handlerId: Identifier | null }
  >({
    accept: "TAB",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      deplacerTab(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop: (item) => item,
  });

  const [{ isDragging }, drag] = useDrag({
    type: "TAB",
    item: () => {
      return { id: variante.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => {
      if (item) {
        tabMoved(item.index);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div
      ref={ref}
      style={{ opacity }}
      className={`z-10 whitespace-nowrap flex pl-2 border-b-2 font-medium cursor-pointer flex items-center ${
        estActif
          ? "border-blue-500 text-blue-600"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-400"
      }`}
      data-handler-id={handlerId}
      onClick={onClick}
    >
      {variante.est_verrouillee && <LockClosedIcon className="h-4 w-4" />}
      {/* {variante.est_masquee && <EyeSlashIcon className="h-4 w-4" />} */}

      {editModeOn ? (
        <div>
          <EditVarianteNameForm
            variante={variante}
            onSubmit={(v) => {
              setEditModeOne(false);
              onChange(v);
            }}
            onClose={() => setEditModeOne(false)}
          />
        </div>
      ) : (
        <span>{variante.nom}</span>
      )}
      {permission === "ADMIN" && (
        <div className="text-blue-600 flex ml-1">
          <Dropdown>
            <DropdownButton plain aria-label="Plus d'options">
              <EllipsisVerticalIcon
                className={estActif ? "stroke-blue-500" : "stroke-gray-300"}
              />
            </DropdownButton>
            <DropdownMenu className="z-20">
              <DropdownItem onClick={() => setEditModeOne(true)}>
                <PencilSquareIcon />
                <DropdownLabel>Éditer</DropdownLabel>
              </DropdownItem>
              <DropdownItem onClick={() => onDuplicate(variante)}>
                <DocumentDuplicateIcon />
                <DropdownLabel>Dupliquer</DropdownLabel>
              </DropdownItem>
              <DropdownSeparator />
              <DropdownItem onClick={() => onToggleLock(variante)}>
                {variante.est_verrouillee ? (
                  <LockOpenIcon />
                ) : (
                  <LockClosedIcon />
                )}
                <DropdownLabel>
                  {variante.est_verrouillee ? "Déverrouiller" : "Verrouiller"}
                </DropdownLabel>
              </DropdownItem>
              <DropdownSeparator />
              <DropdownItem onClick={() => onDelete(variante)}>
                <TrashIcon />
                <DropdownLabel>Supprimer</DropdownLabel>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      )}
    </div>
  );
}
