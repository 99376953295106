import { Button } from "catalyst/button";
import { useForm } from "react-hook-form";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "catalyst/fieldset";
import { Strong, Text } from "catalyst/text";
import { Input } from "catalyst/input";
import { Link } from "react-router-dom";

interface ForgotPasswordForm {
  email: string;
}

interface ForgetPasswordFormProps {
  onSubmit: (d: ForgotPasswordForm) => void;
}

export default function ForgetPasswordForm({
  onSubmit,
}: ForgetPasswordFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ForgotPasswordForm>();

  return (
    <form
      onSubmit={handleSubmit((credentials) => {
        onSubmit(credentials);
        reset();
      })}
    >
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              {...register("email", {
                required: "L'email est obligatoire",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Rentrez un email valide",
                },
              })}
              type="email"
              invalid={errors.email !== undefined}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </Field>
          <Button type="submit" className="w-full">
            Récupérer mon mot de passe
          </Button>
          <Text>
            Déja un compte?{" "}
            <Strong>
              <Link to="/se_connecter">Connectez-vous</Link>
            </Strong>
          </Text>
        </FieldGroup>
      </Fieldset>
    </form>
  );
}
