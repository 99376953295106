import { round } from "lodash";

export function chaineEnNombre(s: string): number {
  const clean = s.replace(/ /g, "").replace(/,/g, ".");
  const v = parseFloat(clean);
  if (isNaN(v)) {
    return 0;
  }
  return round(v, 2);
}

export function getNumber(s: string | number) {
  if (typeof s == "number") return s;
  const clean = s.replace(/ /g, "").replace(/,/g, ".");
  return parseFloat(clean);
}

export function chaineVersMasqueEuro(s: string | number) {
  const number = getNumber(s);
  if (isNaN(number)) {
    return "0,00\xa0€";
  }
  const separateurDesMillier = ".";
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  })
    .format(number)
    .replace(/[\u202f]/g, separateurDesMillier);
}

function masqueNombre(n: number): string {
  if (n % 1 === 0) {
    return `${new Intl.NumberFormat("fr-FR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(n)}`;
  }
  return `${new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(n)}`;
}

export function chaineVersMasquePourcentage(s: string | number) {
  const number = getNumber(s);
  if (isNaN(number)) {
    return "0\xa0%";
  }
  return `${masqueNombre(number)}\xa0%`;
}

export function chaineVersMasqueSurface(s: string | number) {
  const number = getNumber(s);
  if (isNaN(number)) {
    return "0\xa0m²";
  }
  return `${masqueNombre(number)}\xa0m²`;
}

export function chaineVersMasqueAnnees(s: string | number) {
  const number = getNumber(s);
  if (isNaN(number)) {
    return "0 an";
  }
  const roundedNumber = round(number);
  const suffixe = roundedNumber === 0 ? "an" : "ans";
  return `${roundedNumber} ${suffixe}`;
}

export function chaineVersMasqueMois(s: string | number) {
  const number = getNumber(s);
  if (isNaN(number)) {
    return "0 mois";
  }
  return `${round(number)} mois`;
}
