import ForgetPasswordForm from "./ForgetPasswordForm";
import { resetPassword } from "services/api";
import { showError, showSuccess } from "alerts/alertsSlice";
import { AppDispatch } from "store";
import { useDispatch } from "react-redux";

export default function ForgotPasswordPage() {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-zinc-950 dark:text-white">
            Mot de passe oublié
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <ForgetPasswordForm
            onSubmit={({ email }) => {
              resetPassword(email)
                .then(() => {
                  dispatch(
                    showSuccess(
                      `Un lien pour changer votre mot de passe a été envoyé à ${email}.`
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    showError(
                      "Nous sommes désolés, il nous est impossible de changer votre mot de passe."
                    )
                  );
                });
            }}
          />
        </div>
      </div>
    </>
  );
}
