import { differenceInHours } from "date-fns";
import { round } from "lodash";
import {
  CoutFinancement,
  IFinancement,
  LigneChangementDeTaux,
  LigneCoutFinancement,
  LigneDeFinancementCoutFixe,
  LigneDeblocage,
  LigneRemboursement,
} from "types";

export function calculerCoutFinancement(
  financement: IFinancement
): CoutFinancement {
  const lignesCoutFixe = financement.lignes.filter(
    (l): l is LigneDeFinancementCoutFixe => l.type === "COUT_FIXE"
  );
  const lignesCoutFixeAvecCout = lignesCoutFixe.map((ligne) => ({
    id: ligne.id,
    type: ligne.type,
    date: new Date(ligne.date),
    montant: ligne.montant,
    totalDebloque: 0,
    tauxInteret: 0,
    periodeEnJour: 0,
    cout: ligne.montant,
    coutTotal: 0,
    description: ligne.description,
  }));

  const lignesInterets = financement.lignes.filter(
    (l): l is LigneDeblocage | LigneChangementDeTaux | LigneRemboursement =>
      l.type !== "COUT_FIXE"
  );
  const lignesInteretsTriees = lignesInterets
    .map((ligne) => ({ ...ligne, date: new Date(ligne.date) }))
    .sort((l1, l2) => l1.date.valueOf() - l2.date.valueOf());

  let totalDebloque = 0;
  let tauxInteret = 0;
  const lignesInteretsAvecCout = lignesInteretsTriees.map(
    (ligne, i, lignes) => {
      if (ligne.type === "DEBLOCAGE" || ligne.type === "CHANGEMENT_DE_TAUX") {
        tauxInteret = ligne.tauxInteret;
      }
      const estLaDerniereLigne = i === lignes.length - 1;
      const periodeEnJour = estLaDerniereLigne
        ? 0
        : round(differenceInHours(lignes[i + 1].date, ligne.date) / 24) | 0;
      const montant = ligne.type === "CHANGEMENT_DE_TAUX" ? 0 : ligne.montant;
      totalDebloque += montant;
      const cout = ((tauxInteret / 100) * totalDebloque * periodeEnJour) / 365;
      return {
        id: ligne.id,
        type: ligne.type,
        date: ligne.date,
        montant,
        totalDebloque,
        tauxInteret: tauxInteret,
        periodeEnJour,
        cout: round(cout, 2),
        coutTotal: 0,
        description: ligne.description,
      };
    }
  );

  let coutTotal = 0;
  const lignes: LigneCoutFinancement[] = [
    ...lignesCoutFixeAvecCout,
    ...lignesInteretsAvecCout,
  ]
    .sort((l1, l2) => l1.date.valueOf() - l2.date.valueOf())
    .map((ligne) => {
      coutTotal += ligne.cout;
      return {
        ...ligne,
        coutTotal: round(coutTotal, 2),
      };
    });

  return {
    ...financement,
    lignes,
    cout: round(coutTotal, 2),
  };
}
