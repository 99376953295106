import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "catalyst/dialog";
import { Field, Label } from "catalyst/fieldset";
import { Input } from "catalyst/input";
import { useForm } from "react-hook-form";
import { IVariante } from "types";

interface NouvelleVarianteModalProps {
  isOpen: boolean;
  hide: () => void;
  onSubmit: (v: { nom: string }) => void;
}

export default function NouvelleVarianteModal({
  isOpen,
  hide,
  onSubmit,
}: NouvelleVarianteModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IVariante>({ defaultValues: { nom: "" } });

  return (
    <Dialog open={isOpen} onClose={hide}>
      <DialogTitle>Créer une nouvelle variante</DialogTitle>
      <DialogBody>
        <form id="nouveau_variante_form" onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Nom</Label>
            <Input
              type="text"
              data-testid="nouveau_variante_form__nom"
              autoFocus
              {...register("nom", { required: true })}
            />
            {errors.nom && <span>Le nom d'une variante est nécessaire</span>}
          </Field>
        </form>
      </DialogBody>
      <DialogActions>
        <Button key="annuler" color="white" onClick={hide}>
          Annuler
        </Button>
        <Button type="submit" form="nouveau_variante_form">
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
