import { useEffect, useState } from "react";
import Page from "layout/Page";
import { changeVarianteParDefault, getDefaultVariante } from "services/api";
import LoadingState from "components/LoadingState";
import Breadcrumb from "../components/Breadcrumb";
import { showError, showSuccess } from "alerts/alertsSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { getEmptyVariante } from "core/variante";
import VarianteForm from "../projets/Variante";
import { INouvelleVariante } from "types";
import { Button } from "catalyst/button";

export default function PageMonCompte() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const [varianteParDefaut, setVarianteParDefault] =
    useState<INouvelleVariante>(getEmptyVariante);

  useEffect(() => {
    getDefaultVariante()
      .then((variante) => {
        setVarianteParDefault(variante);
      })
      .catch(console.error)
      .then(() => setIsLoading(false));
  }, []);

  return (
    <Page
      breadcrumb={
        <Breadcrumb pages={[{ name: "Mon compte", to: "/mon_compte" }]} />
      }
      className="py-10"
    >
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold leading-6 text-zinc-950">
          Mon compte
        </h1>
        <p className="mt-2 text-sm text-gray-700">Changer vos paramètres</p>
      </div>
      <div>
        {isLoading ? (
          <LoadingState />
        ) : (
          <div className="pt-10">
            <h1 className="text-lg font-semibold leading-6 text-zinc-950">
              Variante par défaut
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Changer les valeurs utilisés par défaut lors de la création d'une
              variante.
            </p>
            <div className="pt-4">
              <VarianteForm
                id="form-variante-par-default"
                variante={varianteParDefaut}
                onSubmit={(variante) => {
                  changeVarianteParDefault(variante)
                    .then(() => {
                      dispatch(
                        showSuccess(
                          "Votre variante par défaut a bien été enregistré"
                        )
                      );
                    })
                    .catch(() => {
                      dispatch(
                        showError(
                          "Nous sommes désolés, il nous est impossible d'enregistrer cette variante par défaut."
                        )
                      );
                    });
                }}
              />
              <div className="text-right py-4">
                <Button
                  type="submit"
                  color="blue"
                  form="form-variante-par-default"
                >
                  Enregistrer
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}
