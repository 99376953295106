import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import "./App.css";
import PageProjets from "projets/PageProjets";
import PageProjet from "projets/PageProjet";
import LoginPage from "auth/LoginPage";
import SignUpPage from "auth/SignUpPage";
import ForgotPasswordPage from "auth/ForgotPasswordPage";
import PasswordResetPage from "auth/PasswordResetPage";
import Page404 from "layout/Page404";
import PageMonCompte from "parametres/PageMonCompte";
import Alerts from "alerts/Alerts";

function App() {
  return (
    <>
      <Alerts />
      <Routes>
        <Route path="/">
          <Route index element={<Navigate replace to="projets" />} />
          <Route element={<Outlet />}>
            <Route path="se_connecter" element={<LoginPage />} />
            <Route path="creer_un_compte" element={<SignUpPage />} />
            <Route
              path="mot_de_passe_oublie"
              element={<ForgotPasswordPage />}
            />
            <Route
              path="nouveau_mot_de_passe/:uid/:token"
              element={<PasswordResetPage />}
            />
          </Route>
          <Route>
            <Route path="projets" element={<PageProjets />} />
            <Route path="projets/:projet_id" element={<PageProjet />} />
            <Route path="mon_compte" element={<PageMonCompte />} />
          </Route>
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
