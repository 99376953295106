import { hide } from "./alertsSlice";
import { useAppDispatch, useAppSelector } from "store";
import {
  CheckCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { IAlert } from "types";
import { selectAlerts } from "./alertsSlice";

function Alert({ alert, hide }: { alert: IAlert; hide: () => void }) {
  const color = alert.type === "danger" ? "red" : "green";
  const icon =
    alert.type === "danger" ? (
      <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
    ) : (
      <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
    );
  return (
    <Transition
      show
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5`}
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">{icon}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className={`text-sm font-medium text-${color}-700`}>
                {alert.title}
              </p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className={`inline-flex rounded-md text-gray-400 hover:text-${color}-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                onClick={hide}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

export default function Alerts() {
  const alerts = useAppSelector(selectAlerts);

  const dispatch = useAppDispatch();

  const alertsArray = Object.values(alerts);
  if (alertsArray.length === 0) return null;
  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
    >
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {alertsArray.map((alert) => (
          <Alert
            key={alert.id}
            alert={alert}
            hide={() => dispatch(hide(alert))}
          />
        ))}
      </div>
    </div>
  );
}
