import { useCallback, useEffect, useState } from "react";
import Page from "layout/Page";
import {
  createProjet,
  deleteProjet,
  getProjets,
  getProjetTags,
  updateProjet,
} from "services/api";
import { IProjet } from "types";
import useModal from "hooks/useModal";
import NouveauProjetModal from "./NouveauProjetModal";
import Breadcrumb from "../components/Breadcrumb";
import ListeDeProjets from "./ListeDeProjets";
import { Button } from "catalyst/button";
import {
  PlusIcon,
  MagnifyingGlassIcon,
  TagIcon,
} from "@heroicons/react/24/outline";
import LoadingState from "components/LoadingState";

function SearchTagInput({
  onSubmit,
  className = "",
  ...props
}: {
  onSubmit: (tags: string[]) => void;
  className?: string;
  [k: string]: any;
}) {
  const [tagFilter, setTagFilter] = useState("");

  return (
    <div className={`relative rounded-md shadow-sm ${className}`} {...props}>
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <TagIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(tagFilter.split(" "));
        }}
      >
        <input
          type="text"
          name="tagFilter"
          id="tagFilter"
          className="block w-full rounded-md border-0 py-1.5 pl-10 text-zinc-950 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Filtrer par libellés"
          value={tagFilter}
          onChange={(event) => setTagFilter(event.target.value)}
        />
      </form>
    </div>
  );
}

export default function PageProjets() {
  const [projets, setProjets] = useState<IProjet[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [tagsFilter, setTagsFilter] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, show, hide } = useModal(false);

  const [page, setPage] = useState(1);
  const [totalProjets, setTotalProjets] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const perPage = 50;

  const getProjetsCallback = useCallback(
    (page: number, perPage: number, textSearch: string, tags: string[]) => {
      getProjets(page, perPage, textSearch, tags)
        .then((response) => {
          setTotalProjets(response.data.count);
          setProjets(response.data.results);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
        });
      getProjetTags().then((response) => setTags(response.data.results));
    },
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getProjetsCallback(page, perPage, textSearch, tagsFilter);
  }, [page, perPage, textSearch, tagsFilter, getProjetsCallback]);

  return (
    <Page
      breadcrumb={<Breadcrumb pages={[{ name: "Projects", to: "/projets" }]} />}
      className="py-10"
    >
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-zinc-950">
            Projets
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            La liste de vos projets d'achat-revente
          </p>
        </div>
        <div className="relative rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getProjetsCallback(page, perPage, textSearch, tagsFilter);
            }}
          >
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-zinc-950 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Rechercher un projet"
              onChange={(event) => setTextSearch(event.target.value)}
            />
          </form>
        </div>
        <SearchTagInput className="ml-4" onSubmit={setTagsFilter} />

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <NouveauProjetModal
            isOpen={isOpen}
            hide={hide}
            onSubmit={(projet) => {
              createProjet(projet).then((response) => {
                hide();
                setProjets([response.data, ...projets]);
              });
            }}
          />
          <Button onClick={show} color="blue">
            <PlusIcon className="stroke-white" />
            Créer un nouveau projet
          </Button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {isLoading ? (
            <div className="flex max-h-dvh h-96 min-w-full items-center justify-center">
              <LoadingState />
            </div>
          ) : (
            <div className="inline-block min-w-full py-2 align-middle">
              <ListeDeProjets
                projets={projets}
                tags={tags}
                mettreAJourProjet={(projet) => {
                  updateProjet(projet).then(() =>
                    getProjetsCallback(page, perPage, textSearch, tagsFilter)
                  );
                }}
                supprimerProjet={(projet) => {
                  deleteProjet(projet).then(() =>
                    getProjetsCallback(page, perPage, textSearch, tagsFilter)
                  );
                }}
              />
              {totalProjets > perPage && (
                <nav
                  className="flex items-center justify-between border-t border-gray-200 bg-white px-3 sm:px-8 py-5"
                  aria-label="Pagination"
                >
                  <div className="hidden sm:block">
                    <p className="text-sm text-gray-700">
                      Projet{" "}
                      <span className="font-medium">
                        {(page - 1) * perPage + 1}
                      </span>{" "}
                      à{" "}
                      <span className="font-medium">
                        {Math.min(page * perPage, totalProjets)}
                      </span>{" "}
                      sur <span className="font-medium">{totalProjets}</span>{" "}
                      projets
                    </p>
                  </div>
                  <div className="flex flex-1 justify-between sm:justify-end">
                    <Button
                      color="white"
                      disabled={page === 1}
                      onClick={() => setPage(page - 1)}
                    >
                      Précédent
                    </Button>
                    <Button
                      color="white"
                      disabled={page * perPage >= totalProjets}
                      className="ml-4"
                      onClick={() => setPage(page + 1)}
                    >
                      Suivant
                    </Button>
                  </div>
                </nav>
              )}
            </div>
          )}
        </div>
      </div>
    </Page>
  );
}
