import { INouveauProjet } from "types";
import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "catalyst/dialog";
import { useForm } from "react-hook-form";
import { Input } from "catalyst/input";
import { Field, Label } from "catalyst/fieldset";

interface NouveauProjetModalProps {
  isOpen: boolean;
  hide: () => void;
  onSubmit: (user: INouveauProjet) => void;
}

export default function NouveauProjetModal({
  isOpen,
  hide,
  onSubmit,
}: NouveauProjetModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<INouveauProjet>({ defaultValues: { nom: "" } });

  return (
    <Dialog open={isOpen} onClose={hide}>
      <DialogTitle>Créer un nouveau projet</DialogTitle>
      <DialogBody>
        <form id="nouveau_projet_form" onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Nom</Label>
            <Input
              type="text"
              data-testid="nouveau_projet_form__nom"
              autoFocus
              {...register("nom", { required: true, minLength: 2 })}
            />
            {errors.nom && <span className="text-red-500">Le nom d'un projet est nécessaire</span>}
          </Field>
        </form>
      </DialogBody>
      <DialogActions>
        <Button key="annuler" color="white" onClick={hide}>
          Annuler
        </Button>
        <Button type="submit" form="nouveau_projet_form" color="blue">
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
