import { useEffect, useState } from "react";
import { IConclusion, INouvelleVariante } from "types";
import hash from "object-hash";
import {
  chaineVersMasqueEuro,
  chaineVersMasquePourcentage,
} from "services/transform";
import { calculer_conclusion } from "core/conclusion";

function ConclusionBloc({
  name,
  value,
  subValue = "",
}: {
  name: string;
  value: string;
  subValue?: string;
}) {
  return (
    <div key={name} className="overflow-hidden bg-white px-4 py-5 sm:p-6">
      <dt className="truncate text-sm font-normal text-gray-900" title={name}>
        {name}
      </dt>
      <dd className="mt-1 text-xl font-semibold tracking-tight text-gray-900">
        {value}
        <span className="ml-2 text-sm hidden lg:inline truncate font-medium text-zinc-500">
          {subValue}
        </span>
      </dd>
      <dd className="mt-1 text-sm font-semibold block lg:hidden tracking-tight text-zinc-500">
        {subValue}
      </dd>
    </div>
  );
}

export default function Conclusion<T extends INouvelleVariante>({
  variante,
}: {
  variante: T;
}) {
  const [conclusion, setConclusion] = useState<IConclusion | null>(null);
  const varianteHash = hash(variante);
  useEffect(() => {
    setConclusion(calculer_conclusion(variante));
  }, [variante, varianteHash]);

  if (conclusion === null) return null;

  return (
    <div className="py-4 bg-gray-50">
      <dl className="grid grid-cols-2 gap-px bg-zinc-950/10 lg:grid-cols-4 2xl:grid-cols-8 border border-solid border-zinc-950/10">
        {[
          {
            name: "Chiffre d'affaire",
            stat: chaineVersMasqueEuro(conclusion.chiffre_affaire),
          },
          {
            name: "Cout total",
            stat: chaineVersMasqueEuro(conclusion.cout_total),
          },
          {
            name: "Cout revient opérationnel",
            stat: chaineVersMasqueEuro(conclusion.cout_revient_operationnel),
          },
          {
            name: "Marge avant TVA nette",
            stat: chaineVersMasqueEuro(conclusion.marge_avant_TVA),
          },
          {
            name: "TVA",
            stat: chaineVersMasqueEuro(conclusion.TVA),
          },
          {
            name: "Bénéfice avant impôt",
            stat: chaineVersMasqueEuro(conclusion.benefice_avant_impot),
          },
        ].map((item) => (
          <ConclusionBloc key={item.name} name={item.name} value={item.stat} />
        ))}
        <ConclusionBloc
          name="Rentabilité brute sur coût total"
          value={chaineVersMasquePourcentage(conclusion.rentabilite_brute)}
          subValue={`${chaineVersMasquePourcentage(
            conclusion.rentabilite_annuelle
          )} / an`}
        />
        <ConclusionBloc
          name="Rentabilité brute sur CA"
          value={chaineVersMasquePourcentage(
            conclusion.rentabilite_brute_sur_ca
          )}
          subValue={`${chaineVersMasquePourcentage(
            conclusion.rentabilite_brute_sur_ca_annuelle
          )} / an`}
        />
      </dl>
    </div>
  );
}
