import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "catalyst/dialog";
import { Field, Label } from "catalyst/fieldset";
import { Input } from "catalyst/input";
import { useForm } from "react-hook-form";
import { IProjet } from "types";

interface EditProjetModalProps {
  projet: IProjet;
  isOpen: boolean;
  hide: () => void;
  onSubmit: (v: IProjet) => void;
}

export default function EditProjetModal({
  projet,
  isOpen,
  hide,
  onSubmit,
}: EditProjetModalProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IProjet>({ defaultValues: projet });

  return (
    <Dialog open={isOpen} onClose={hide}>
      <DialogTitle>Editer un projet</DialogTitle>
      <DialogBody>
        <form id="edit_projet_form" onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Nom</Label>
            <Input
              type="text"
              data-testid="edit_projet_form__nom"
              autoFocus
              {...register("nom", { required: true, minLength: 2 })}
            />
            {errors.nom && (
              <span className="text-red-500">
                Le nom d'un projet est nécessaire
              </span>
            )}
          </Field>
        </form>
      </DialogBody>
      <DialogActions>
        <Button color="white" onClick={hide}>
          Annuler
        </Button>
        <Button type="submit" form="edit_projet_form" color="blue">
          Editer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
