import { Button } from "catalyst/button";
import { useForm } from "react-hook-form";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "catalyst/fieldset";
import { Strong, Text } from "catalyst/text";
import { Input } from "catalyst/input";
import { Link } from "react-router-dom";
import { ISignUpForm } from "types";

interface SignUpFormProps {
  onSubmit: (credentials: ISignUpForm) => Promise<void>;
}

export default function SignUpForm({ onSubmit }: SignUpFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignUpForm>();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Fieldset>
        <FieldGroup>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-4">
            <Field>
              <Label>Prénom</Label>
              <Input
                id="prenom"
                {...register("prenom", {
                  required: "Le prénom est obligatoire",
                })}
                type="text"
                invalid={errors.prenom !== undefined}
              />
            </Field>
            <Field>
              <Label>Nom</Label>
              <Input
                id="nom"
                {...register("nom", {
                  required: "Le nom est obligatoire",
                })}
                type="text"
                invalid={errors.nom !== undefined}
              />
            </Field>
          </div>
          <Field>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              {...register("email", {
                required: "L'email est obligatoire",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Rentrez un email valide",
                },
              })}
              type="email"
              autoComplete="username"
              invalid={errors.email !== undefined}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </Field>
          <Field>
            <Label htmlFor="password">Mot de passe</Label>
            <Input
              id="password"
              {...register("password", {
                required: "Le mot de passe est obligatoire",
                minLength: {
                  value: 8,
                  message: "Votre mot de passe est trop court",
                },
              })}
              autoComplete="current-password"
              type="password"
              invalid={errors.password !== undefined}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </Field>
          <Button type="submit" className="w-full">
            Créer un compte
          </Button>
          <Text>
            Déja un compte?{" "}
            <Strong>
              <Link to="/se_connecter">Connectez-vous</Link>
            </Strong>
          </Text>
        </FieldGroup>
      </Fieldset>
    </form>
  );
}
