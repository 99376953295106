import { ITokenFromAPI, ITokens } from "types";

const TOKEN = "Bopar";

export function getTokens(): ITokens | null {
  const token = localStorage.getItem(TOKEN);
  if (!token) return null;
  return JSON.parse(token);
}

export function saveTokens(tokens: ITokenFromAPI) {
  localStorage.setItem(
    TOKEN,
    JSON.stringify({
      accessToken: tokens.access,
      refreshToken: tokens.refresh,
    })
  );
}

export function removeTokens() {
  localStorage.removeItem(TOKEN);
}
