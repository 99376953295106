import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./authContext";
import { parseErreur } from "./erreur";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { showError } from "alerts/alertsSlice";

export default function BoparLoginPage() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-zinc-950 dark:text-white">
          Se connecter
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <LoginForm
          onSubmit={(c) => {
            login(c)
              .then(() => navigate("/"))
              .catch((e) => {
                const erreur = parseErreur(e?.response?.data);
                if (erreur) {
                  dispatch(showError(erreur));
                } else {
                  dispatch(
                    showError(
                      "Nous sommes désolés, une erreur est survenue. Veuillez contacter le service client si l'erreur persiste. Merci."
                    )
                  );
                }
              });
          }}
        />
      </div>
    </div>
  );
}
