import { useEffect, useRef, useState } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import {
  chaineVersMasqueEuro,
  chaineVersMasqueMois,
  chaineVersMasquePourcentage,
  chaineVersMasqueSurface,
  getNumber,
} from "services/transform";
import { evaluate } from "mathjs";
import { Input } from "catalyst/input";

type MaskType = "number" | "percentage" | "mois" | "surface";

function applyMaskFunction(value: string | number | undefined, type: MaskType) {
  const maskFunction =
    type === "number"
      ? chaineVersMasqueEuro
      : type === "mois"
        ? chaineVersMasqueMois
        : type === "surface"
          ? chaineVersMasqueSurface
          : chaineVersMasquePourcentage;

  if (value === undefined) {
    return "";
  }
  return maskFunction(value);
}

function InputNumber<T extends FieldValues>({
  type = "number",
  className = "",
  placeholder = "",
  ...props
}: UseControllerProps<T> & {
  type?: MaskType;
  className?: string;
  placeholder?: string;
}) {
  const { field } = useController(props);
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string>(field.value);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const fromStringToNumber = (v: string): number => {
    try {
      if (v.startsWith("=")) {
        const mathExpression = v.substring(1);
        return evaluate(mathExpression);
      } else {
        const number = getNumber(v);
        return isNaN(number) ? 0 : number;
      }
    } catch (error) {
      return 0;
    }
  };

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.select();
    }
  }, [isFocused]);

  return (
    <Input
      {...field}
      ref={(element) => {
        inputRef.current = element;
        field.ref(element);
      }}
      value={isFocused ? value : applyMaskFunction(value, type)}
      onFocus={(event) => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
        setValue(field.value);
      }}
      onChange={(event) => {
        field.onChange(fromStringToNumber(event.target.value));
        setValue(event.target.value);
      }}
      className={className}
      placeholder={placeholder}
    />
  );
}

export default InputNumber;
