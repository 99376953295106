import { Link } from "react-router-dom";
import { IProjet } from "types";
import ConfirmDeleteModal from "components/ConfirmDeleteModal";
import { compareDesc, formatDistanceToNow, parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import CreatableSelect from "react-select/creatable";
import { OnChangeValue } from "react-select";
import { BadgeButton } from "catalyst/badge";

export default function ListeDeProjets({
  projets,
  tags,
  mettreAJourProjet,
  supprimerProjet,
}: {
  projets: IProjet[];
  tags: string[];
  mettreAJourProjet: (projet: IProjet) => void;
  supprimerProjet: (projet: IProjet) => void;
}) {
  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-zinc-950 sm:pl-6 lg:pl-8"
          >
            Nom
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-950"
          >
            Créé
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-zinc-950"
          >
            Libellés
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
            <span className="sr-only">Delete</span>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {projets
          .sort((p1, p2) =>
            compareDesc(parseISO(p1.creeLe), parseISO(p2.creeLe))
          )
          .map((projet) => (
            <tr key={projet.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-blue-500 sm:pl-6 lg:pl-8">
                <Link to={`/projets/${projet.id}`}>{projet.nom}</Link>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                {`il y a ${formatDistanceToNow(parseISO(projet.creeLe), {
                  locale: fr,
                })}`}
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <div className="flex space-x-2">
                  <CreatableSelect
                    isMulti
                    isClearable={false}
                    onChange={(
                      newValue: OnChangeValue<
                        {
                          readonly value: string;
                          readonly label: string;
                        },
                        true
                      >
                    ) => {
                      mettreAJourProjet({
                        ...projet,
                        tags: newValue.map((t) => t.value),
                      });
                    }}
                    options={tags.map((t) => ({
                      value: t,
                      label: t,
                    }))}
                    value={projet.tags?.map((t) => ({
                      value: t,
                      label: t,
                    }))}
                    formatCreateLabel={(input) => `Créer libellé ${input}`}
                    placeholder="Créer un libellé"
                    className={`${
                      projet.tags?.length === 0 ? "opacity-50" : "opacity-100"
                    } hover:opacity-100`}
                    closeMenuOnSelect={false}
                    noOptionsMessage={() => null}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        minWidth: 150,
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </div>
              </td>
              <td className="relative whitespace-nowrap px-3 sm:px-8 py-4 text-right text-sm font-medium ">
                {projet.partage ? null : (
                  <ConfirmDeleteModal
                    titre={`Etes vous sur de vouloir supprimer ce projet ?`}
                    message={`Vous êtes sur le point de supprimer le projet ${projet.nom}. Assurez vous de ne plus avoir besoin de ce projet avant de cliquer sur oui.`}
                    onOk={() => {
                      supprimerProjet(projet);
                    }}
                  >
                    {(openModal) => (
                      <BadgeButton color="red" onClick={openModal}>
                        supprimer
                      </BadgeButton>
                    )}
                  </ConfirmDeleteModal>
                )}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
