import PasswordResetForm from "./PasswordResetForm";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { confirmResetPassword } from "services/api";
import { showError, showSuccess } from "alerts/alertsSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store";
import { parseErreur } from "./erreur";

export default function PasswordResetPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { uid, token } = useParams();
  if (uid === undefined || token === undefined) {
    return <Navigate to="/se_connecter" replace />;
  }
  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-zinc-950 dark:text-white">
            Changer votre mot de passe
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <PasswordResetForm
            onSubmit={({ password }) => {
              confirmResetPassword(uid, token, password)
                .then(() => {
                  dispatch(
                    showSuccess(
                      "Nous avons changé votre mot de passe avec succès. Vous pouvez vous connecter maintenant."
                    )
                  );
                  navigate("/se_connecter");
                })
                .catch((e) => {
                  const erreur = parseErreur(e?.response?.data);
                  if (erreur) {
                    dispatch(showError(erreur));
                  } else {
                    dispatch(
                      showError(
                        "Nous sommes désolés, une erreur est survenue. Veuillez contacter le service client si l'erreur persiste. Merci."
                      )
                    );
                  }
                });
            }}
          />
        </div>
      </div>
    </>
  );
}
