export function parseErreur(erreur: any): string | undefined {
  if (
    erreur &&
    "email" in erreur &&
    erreur["email"].join("").indexOf("existe") !== -1
  ) {
    return "Un utilisateur avec cet email existe déjà. Vous voulez peut être vous connecter ?";
  }
  if (!erreur) return;

  if ("password" in erreur) {
    const erreurs = erreur["password"].join("");
    if (erreurs.indexOf("contenir au minimum") !== -1) {
      return "Le mot de passe que vous avez fourni n'est pas bon. Veuillez choisir un mot de passe avec au minimum 8 caractères.";
    }
    if (erreurs.indexOf("trop courant") !== -1) {
      return "Le mot de passe que vous avez fourni est faible et peut être facilement deviné. Veuillez choisir un mot de passe plus fort.";
    }
  }
  if ("new_password" in erreur) {
    const erreurs = erreur["new_password"].join("");
    if (erreurs.indexOf("contenir au minimum") !== -1) {
      return "Le nouveau mot de passe que vous avez fourni n'est pas bon. Veuillez choisir un mot de passe avec au minimum 8 caractères.";
    }
    if (erreurs.indexOf("trop courant") !== -1) {
      return "Le nouveau mot de passe que vous avez fourni est faible et peut être facilement deviné. Veuillez choisir un mot de passe plus fort.";
    }
  }
  if ("detail" in erreur) {
    return "Aucun compte actif trouvé avec les informations d'identification fournies";
  }
  if ("message" in erreur) {
    return erreur["message"];
  }
}
