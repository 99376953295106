export function paginationVersParametresAPI(
  page: number,
  perPage: number,
  search: string,
  tags?: string[]
) {
  const filters = {
    limit: perPage,
    offset: (page - 1) * perPage,
    search,
  };
  if (tags) {
    return {
      ...filters,
      tags: tags.join(","),
    };
  }
  return filters;
}
