import React, { Fragment, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useResolvedPath } from "react-router-dom";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  FolderIcon,
  UserIcon,
  XMarkIcon,
  QuestionMarkCircleIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { useAuth, AuthContextProps } from "auth/authContext";
import Alerts from "alerts/Alerts";
import Logo from "../images/logo-blanc.png";
import useLocalStorage from "hooks/useLocalStorage";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  { name: "Projets", to: "/projets", icon: FolderIcon },
  { name: "Mon compte", to: "/mon_compte", icon: UserIcon },
];

function IsCurrent({
  children,
  to,
}: {
  children: (isCurrent: boolean) => React.ReactNode;
  to: string;
}) {
  const location = useLocation();
  const path = useResolvedPath(to);
  const isCurrent = location.pathname.startsWith(path.pathname);
  return <>{children(isCurrent)}</>;
}

interface PageProps {
  breadcrumb?: React.ReactNode;
  className?: string;
  children: React.ReactNode;
}

export default function Page({
  breadcrumb,
  children,
  className = "",
}: PageProps) {
  const { currentUser, logout }: AuthContextProps = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSidebar, setShowSidebar] = useLocalStorage(
    "showSidebarPage",
    true
  );

  const location = useLocation();

  if (currentUser === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/se_connecter" state={{ from: location }} replace />;
  }

  return (
    <>
      <Alerts />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 w-auto" src={Logo} alt="Bopar" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <IsCurrent to={item.to}>
                                  {(isCurrent) => (
                                    <Link
                                      to={item.to}
                                      className={classNames(
                                        isCurrent
                                          ? "bg-gray-800 text-white"
                                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                      )}
                                    >
                                      <item.icon
                                        className="h-6 w-6 shrink-0"
                                        aria-hidden="true"
                                      />
                                      {item.name}
                                    </Link>
                                  )}
                                </IsCurrent>
                              </li>
                            ))}
                            <li>
                              <a
                                href="https://groupe-locus.notion.site/Tutoriel-BOPAR-83601abf0a8b4752a98648743c154e4c?pvs=4"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              >
                                <AcademicCapIcon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                Tutoriel
                              </a>
                            </li>
                            <li>
                              <a
                                href="mailto:support@yakademie.com"
                                className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              >
                                <QuestionMarkCircleIcon
                                  className="h-6 w-6 shrink-0"
                                  aria-hidden="true"
                                />
                                Contact
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden ${
            showSidebar
              ? "lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
              : ""
          }`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-white"
                onClick={() => setShowSidebar(false)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6 mr-4" aria-hidden="true" />
              </button>
              <img className="h-8 w-auto" src={Logo} alt="Bopar" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <IsCurrent to={item.to}>
                          {(isCurrent) => (
                            <Link
                              to={item.to}
                              className={classNames(
                                isCurrent
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          )}
                        </IsCurrent>
                      </li>
                    ))}
                    <li>
                      <a
                        href="https://groupe-locus.notion.site/Tutoriel-BOPAR-83601abf0a8b4752a98648743c154e4c?pvs=4"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <AcademicCapIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        Tutoriel
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:support@yakademie.com"
                        className="text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        <QuestionMarkCircleIcon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        Contact
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className={showSidebar ? "lg:pl-72" : ""}>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className={`-m-2.5 p-2.5 text-gray-700 ${
                showSidebar ? "lg:hidden" : ""
              }`}
              onClick={() => {
                setShowSidebar(!showSidebar);
                setSidebarOpen(true);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className={`h-6 w-px bg-gray-900/10 ${
                showSidebar ? "lg:hidden" : ""
              }`}
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex flex-1">{breadcrumb && breadcrumb}</div>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Ouvrir menu utilisateur</span>
                    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        className="ml-4 text-sm font-semibold leading-6 text-zinc-950"
                        aria-hidden="true"
                      >
                        {currentUser.email}
                      </span>
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/mon_compte"
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-zinc-950"
                            )}
                          >
                            Mon compte
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={logout}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-zinc-950"
                            )}
                          >
                            Se déconnecter
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className={`px-4 sm:px-6 lg:px-8 ${className}`}>
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
