import { Button } from "catalyst/button";
import { ICredentials } from "types";
import { useForm } from "react-hook-form";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "catalyst/fieldset";
import { Strong, Text } from "catalyst/text";
import { Input } from "catalyst/input";
import { Link } from "react-router-dom";

interface CreateUserFormProps {
  onSubmit: (credentials: ICredentials) => void;
}

export default function CreateUserForm({ onSubmit }: CreateUserFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ICredentials>();
  return (
    <form
      onSubmit={handleSubmit((credentials) => {
        onSubmit(credentials);
        reset();
      })}
    >
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              {...register("email", {
                required: "L'email est obligatoire",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Rentrez un email valide",
                },
              })}
              type="email"
              autoComplete="username"
              invalid={errors.email !== undefined}
            />
            {errors.email && (
              <ErrorMessage>{errors.email.message}</ErrorMessage>
            )}
          </Field>
          <Field>
            <Label htmlFor="password">Mot de passe</Label>
            <Input
              id="password"
              {...register("password", {
                required: "Le mot de passe est obligatoire",
              })}
              type="password"
              autoComplete="current-password"
              invalid={errors.password !== undefined}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </Field>
          <div className="flex flex-wrap justify-end">
            <Text>
              <Strong>
                <Link to="/mot_de_passe_oublie">Mot de passe oublié?</Link>
              </Strong>
            </Text>
          </div>
          <Button type="submit" className="w-full">
            Se connecter
          </Button>
          <Text>
            Pas encore de compte ?{" "}
            <Strong>
              <Link to="/creer_un_compte">Créer en un</Link>
            </Strong>
          </Text>
        </FieldGroup>
      </Fieldset>
    </form>
  );
}
