import { ReactNode } from "react";
import useModal from "../hooks/useModal";
import { Button } from "catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "catalyst/dialog";

type ConfirmDeleteModalProps = {
  onOk: () => void;
  children: (open: () => void) => ReactNode;
  titre?: string;
  message?: string;
  okButton?: string;
  bouttonAnnuler?: string;
};

const ConfirmDeleteModal = ({
  titre = "Etes vous sur ?",
  message = "",
  okButton = "Oui",
  bouttonAnnuler = "Annuler",
  onOk,
  children,
}: ConfirmDeleteModalProps) => {
  const { isOpen, show, hide } = useModal(false);
  return (
    <>
      <Dialog title="modale-confirm-supprimer" open={isOpen} onClose={hide}>
        <DialogTitle>
          <span className="text-red-500">{titre}</span>
        </DialogTitle>
        <DialogDescription>
          <span className="text-red-500">{message}</span>
        </DialogDescription>
        <DialogActions>
          <Button
            key="confirmer"
            onClick={() => {
              hide();
              onOk();
            }}
            plain
            className="ml-4 text-red-800"
          >
            {okButton}
          </Button>
          <Button color="blue" onClick={hide} autoFocus>
            {bouttonAnnuler}
          </Button>
        </DialogActions>
      </Dialog>
      {children(show)}
    </>
  );
};

export default ConfirmDeleteModal;
