import { Button } from "catalyst/button";
import { useForm } from "react-hook-form";
import {
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "catalyst/fieldset";
import { Input } from "catalyst/input";

interface ResetPasswordForm {
  password: string;
}

interface PasswordResetFormProps {
  onSubmit: (d: ResetPasswordForm) => void;
}

export default function ForgetPasswordForm({
  onSubmit,
}: PasswordResetFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ResetPasswordForm>();

  return (
    <form
      onSubmit={handleSubmit((credentials) => {
        onSubmit(credentials);
        reset();
      })}
    >
      <Fieldset>
        <FieldGroup>
          <Field>
            <Label htmlFor="password">Nouveau mot de passe</Label>
            <Input
              id="password"
              {...register("password", {
                required: "Le mot de passe est obligatoire",
                minLength: {
                  value: 8,
                  message: "Votre nouveau mot de passe est trop court",
                },
              })}
              type="password"
              autoComplete="new-password"
              invalid={errors.password !== undefined}
            />
            {errors.password && (
              <ErrorMessage>{errors.password.message}</ErrorMessage>
            )}
          </Field>
          <Button type="submit" className="w-full">
            Changer mon mot de passe
          </Button>
        </FieldGroup>
      </Fieldset>
    </form>
  );
}
